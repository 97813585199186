import { FC } from 'react'
import { SpeakerSummaryFragment } from 'generated/graphql'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { Sizes } from 'utility/Sizes'
import { useState } from 'react'
import { SpeakerModal } from 'content-types/Speaker/Speaker.Modal/Speaker.Modal'

import styles from './Speaker.Summary.module.scss'

export const SpeakerSummary: FC<SpeakerSummaryFragment> = ({
    ...speaker
}) => {

    const [ open, setOpen ] = useState<boolean>(false)

    return (
        <>
            <SpeakerModal open={open} setOpen={setOpen} {...speaker} />
            <div className={styles.Preview} onClick={ () => setOpen(true) }>
                <div className={styles.Speaker}>
                    {speaker.photo ?
                        <ComponentImagePicture
                            {...speaker.photo}
                            className={styles.Image}
                            sizes={Sizes.Featured}
                        />
                        : <></>
                    }
                    <div className={styles.SpeakerText}>
                        <p>{speaker.forename} {speaker.surname}</p>
                        <p>{speaker.jobTitle}</p>
                        <p>{speaker.company?.data?.attributes?.title}</p>
                    </div>
                    <div className={styles.CompanyLogo}>
                        {speaker.company?.data?.attributes?.picture ?
                            <ComponentImagePicture
                                {...speaker.company.data.attributes.picture}
                                className={styles.Company}
                                sizes={Sizes.Featured}
                            />
                            : <></>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
    