import { FC } from 'react'

type ChangeHandler = (value: string) => void

type WithEventType = {
    eventType: string | null
}

type EventTypeFilterArgs = {
    value: string | null,
    onChange: ChangeHandler,
    className?: string,
    objects: readonly WithEventType[]
}

export const EventTypeFilter: FC<EventTypeFilterArgs> =
    ({
        value,
        onChange,
        className,
        objects
    }) => {

        const eventTypes =
            objects
                .map( objects => objects?.eventType )
                .filter( (eventType,index,array): eventType is string =>
                    eventType !== null && array.indexOf(eventType) === index
                )
                .sort( (a, b) => a.localeCompare(b) )

        return <span className={className}>
            <label>Type</label>
            <select
                value={value ?? ''}
                onChange={
                    ({target:{value}}): void =>
                        onChange(value)
                }
                title='Event Type'
            >
                <option value='all'>All</option>
                {
                    eventTypes.map( eventType =>
                        <option key={eventType} value={eventType}>{eventType}</option>
                    )
                }
            </select>
        </span>

    }

