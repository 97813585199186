import { FC } from 'react'

import { Activity, WithActivityFragment } from 'generated/graphql'

type ChangeHandler = (value: string) => void

type WithActivities = {
    activities: WithActivityFragment | null
}

type ActivityFilterArgs = {
    value: string | null,
    onChange: ChangeHandler,
    className?: string,
    objects: readonly WithActivities[],
    expand: boolean
}

export const ActivityFilter: FC<ActivityFilterArgs> =
    ({
        value,
        onChange,
        className,
        objects
    }) => {

        const activities =
            objects
                .flatMap( object =>
                    object.activities?.data.map( activity =>
                        activity.attributes
                    )
                )
                .filter( (activity,index,array): activity is Pick<Activity,'slug'|'title'> =>
                    activity !== undefined
                    && activity !== null
                    && index === array.findIndex( x => x?.slug === activity.slug )
                )
                .sort( (a, b) => a.title.localeCompare(b.title) )

        return  <span className={className}>
            <label>Business Area</label>
            <select
                value={value ?? ''}
                onChange={
                    ({target:{value}}): void =>
                        onChange(value)
                }
                title='Business Area'
            >
                <option value='all'>All</option>
                {
                    activities?.map( ({slug,title}) =>
                        <option key={slug} value={slug}>{title}</option>
                    )
                }
            </select>
        </span>

    }

