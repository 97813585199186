import { Markdown } from 'components/shared/Markdown/Markdown'
import { DatabaseSummaryFragment } from 'generated/graphql'
import { ContentTypeView, Sizes } from 'utility/utility'
import { DatabaseLink } from 'content-types/Database/Database.Link/Database.Link'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { MetadataTag } from 'components/shared/MetadataTag/MetadataTag'
import { Summary } from 'components/shared/Summary/Summary'

export const DatabaseSummary: ContentTypeView<DatabaseSummaryFragment> = ({
    summary,
    picture,
    siteContext,
    pageContext,
    articleContext,
    ...database
}) =>
    <Summary
        image={
            <DatabaseLink
                siteContext={siteContext}
                {...database}
            >
                <ComponentImagePicture
                    {...picture}
                    sizes={Sizes.Summary}
                />
            </DatabaseLink>
        }
        header={
            <>
                <MetadataTag>Database</MetadataTag>
                <h1>
                    <DatabaseLink
                        siteContext={siteContext}
                        {...database}
                    >
                        { database.title }
                    </DatabaseLink>
                </h1>
            </>
        }
        summary={
            <Markdown
                siteContext={siteContext}
                pageContext={pageContext}
                articleContext={articleContext}
            >
                {summary}
            </Markdown>
        }
    />
