import { ContentTypeView } from 'utility/utility'

import { ArticleFeatured } from 'content-types/Article/Article.Featured/Article.Featured'
import { DatabaseFeatured } from 'content-types/Database/Database.Featured/Database.Featured'
import { DatasetFeatured } from 'content-types/Dataset/Dataset.Featured/Dataset.Featured'
import { EventFeatured } from 'content-types/Event/Event.Featured/Event.Featured'
import { InsightReportFeatured } from 'content-types/InsightReport/InsightReport.Featured/InsightReport.Featured'
import { MagazineFeatured } from 'content-types/Magazine/Magazine.Featured/Magazine.Featured'
import { MarketReportFeatured } from 'content-types/MarketReport/MarketReport.Featured/MarketReport.Featured'
import { NewsletterFeatured } from 'content-types/Newsletter/Newsletter.Featured/Newsletter.Featured'

import type { Content } from './ComponentBodyRelatedContentList'

export const ContentFeatured: ContentTypeView<Content> = (content) => {

    switch ( content.__typename ) {

        case 'Article':
            return <ArticleFeatured key={content.slug} {...content} />

        case 'Database':
            return <DatabaseFeatured key={content.slug} {...content} />

        case 'Dataset':
            return <DatasetFeatured key={content.slug} {...content} />

        case 'Event':
            return <EventFeatured key={content.slug} {...content} />

        case 'InsightReport':
            return <InsightReportFeatured key={content.slug} {...content} />

        case 'Magazine':
            return <MagazineFeatured key={content.slug} {...content} />

        case 'MarketReport':
            return <MarketReportFeatured key={content.slug} {...content} />

        case 'Newsletter':
            return <NewsletterFeatured key={content.title} {...content} />

        case undefined:
            return <></>

    }

}
