import { FC } from 'react'
import { WithArticleTypeFragment } from 'generated/graphql'

type ChangeHandler = (value: string) => void

type WithArticleType = {
    articleType: WithArticleTypeFragment | null
}

type ArticleTypeFilterArgs = {
    value: string | null,
    onChange: ChangeHandler,
    className?: string,
    objects: readonly WithArticleType[]
    expand: boolean
}

export const ArticleTypeFilter: FC<ArticleTypeFilterArgs> =
    ({
        value,
        onChange,
        className,
        objects,
        expand
    }) => {

        const articleTypes =
            objects
                .map( objects => objects?.articleType?.data?.attributes?.title )
                .filter( (title,index,array): title is string =>
                    title !== undefined && array.indexOf(title) === index
                )
                .sort( (a, b) => a.localeCompare(b) )

        return expand ?
            <span className={className} data-expanded={expand}>
                <button
                    key='all'
                    onClick={ () => onChange('all') }
                    data-active={ value === null }
                >
                    All
                </button>
                {
                    articleTypes.map( title =>
                        <button
                            key={title}
                            onClick={ () => onChange(title) }
                            data-active={ value === title }
                        >
                            {title}
                        </button>
                    )
                }
            </span>
        :
            <span className={className}>
                <label>Type</label>
                <select
                    value={value ?? ''}
                    onChange={
                        ({target:{value}}): void =>
                            onChange(value)
                    }
                    title='Article Type'

                >
                    <option value='all'>All</option>
                    {
                        articleTypes.map( title =>
                            <option key={title} value={title}>{title}</option>
                        )
                    }
                </select>
            </span>

    }

