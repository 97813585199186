'use client'

import { FC, PropsWithChildren } from 'react'
import { useRouter, usePathname } from 'next/navigation'

import styles from './Filters.module.scss'
import buttonStyles from 'styles/button.module.scss'

type FiltersData = {
    className?: string,
    expanded?: boolean | null
}

export const Filters: FC<PropsWithChildren<FiltersData>> =
    ({
        children,
        className = '',
        expanded
    }) => {

        const router = useRouter()
        const pathname = usePathname()

        const resetFilters = (): void =>
            router.replace(pathname, { scroll: false } )

        return(
            <header
                className={`${styles.Filters} ${className}`}
                data-expanded={expanded ?? false}
            >
                {children}
                <button
                    className={buttonStyles.Link}
                    onClick={ (): void => resetFilters() }
                >
                    Reset
                </button>
            </header>
        )

    }
