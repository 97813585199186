'use client'

import { produce } from 'immer'
import { useRouter, usePathname, useSearchParams } from 'next/navigation'
import { type BodyObjectView } from 'utility/utility'
import type { Content } from './ComponentBodyRelatedContentList'

import { Filters } from 'components/shared/Filters/Filters'
import { ContentTypeFilter } from 'components/filters/ContentTypeFilter/ContentTypeFilter'
import { ContentFeatured } from './ContentFeatured'

export const RelatedContentFilterList: BodyObjectView<{
        content: readonly Content[],
        maximumNumber: number
    }> =
        ({
            content,
            maximumNumber = 256,
            siteContext,
            pageContext,
            articleContext
        }) => {

        const router = useRouter()
        const pathname = usePathname()
        const searchParams = useSearchParams()

        const type = searchParams.get('type')

        const updateQuery = (updates: { [key: string]: string } ): void => {
            const params = produce(Object.fromEntries(searchParams.entries()), draft => {
                Object.entries(updates).forEach( ([field,value]) => {
                    if ( value === 'all' ) {
                        delete draft[field]
                    }
                    else {
                        draft[field] = value
                    }
                })
            })
            const query = new URLSearchParams([...Object.entries(params)])
            router.replace(
                `${pathname}${ query.size === 0 ? '' : `?${query.toString()}` }`,
                { scroll: false }
            )
        }

        const setType = (type: string): void =>
            updateQuery({type})

        return (
            <>
                <Filters>
                    <ContentTypeFilter
                        key='content_type'
                        value={type}
                        onChange={setType}
                    />
                </Filters>
                <ul>
                    {
                        content
                            .filter( ({__typename}) =>
                                type === null
                                || type === ''
                                || type === 'all'
                                || ( type === 'event' && __typename === 'Event' )
                                || ( type === 'insight-report' && __typename === 'InsightReport' )
                                || ( type === 'market-report' && __typename === 'MarketReport' )
                                || ( type === 'database' && __typename === 'Database' )
                                || ( type === 'dataset' && __typename === 'Dataset' )
                                || ( type === 'magazine' && __typename === 'Magazine' )
                                || ( type === 'newsletter' && __typename === 'Newsletter' )
                                || ( type === 'article' && __typename === 'Article' )
                            )
                            .slice(0, maximumNumber)
                            .map( content => {

                                const contentFeatured = <ContentFeatured
                                    siteContext={siteContext}
                                    pageContext={pageContext}
                                    articleContext={articleContext}
                                    {...content}
                                />

                                return (
                                    <li key={contentFeatured.key}>
                                        {contentFeatured}
                                    </li>
                                )
                            })
                    }
                </ul>
            </>
        )

    }
