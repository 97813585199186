import { ContentTypeView, NextUrl, Sizes } from 'utility/utility'
import { Featured } from 'components/shared/Featured/Featured'
import { MetadataTag } from 'components/shared/MetadataTag/MetadataTag'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { NewsletterFeaturedFragment } from 'generated/graphql'
import { NewsletterLink } from 'content-types/Newsletter/Newsletter.Link/Newsletter.Link'

export const NewsletterFeatured: ContentTypeView<NextUrl<NewsletterFeaturedFragment>> =
    ({
        picture,
        ...newsletter
    }) =>
        <Featured
            tags={
                <MetadataTag>Newsletter</MetadataTag>
            }
            link={
                <NewsletterLink {...newsletter}>
                    {
                        picture === undefined ? <></> :
                            <ComponentImagePicture
                                {...picture}
                                sizes={Sizes.Featured}
                            />
                    }
                    { newsletter.title }
                </NewsletterLink>
            }
        />
