import { Markdown } from 'components/shared/Markdown/Markdown'
import { ContentTypeView, formatDateRange, shortVenueSummary, Sizes } from 'utility/utility'
import { EventSummaryFragment } from 'generated/graphql'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { Summary } from 'components/shared/Summary/Summary'
import { EventBookingLink } from 'content-types/Event/Event.BookingLink/Event.BookingLink'
import { ComponentMenuExternalLink } from 'components/menu/ComponentMenuExternalLink/ComponentMenuExternalLink'

import styles from './Event.Summary.module.scss'
import buttonStyles from 'styles/button.module.scss'

export const EventSummary: ContentTypeView<EventSummaryFragment> = ({
    tagline,
    summary,
    details,
    freeDate,
    startDate,
    endDate,
    venue,
    picture,
    bookingLinkText,
    siteContext,
    pageContext,
    articleContext,
    links,
    ...event
}) =>
    <Summary
        image={
            picture === undefined ? <></> :
                <EventBookingLink
                    siteContext={siteContext}
                    {...event}
                >
                    <ComponentImagePicture
                        {...picture}
                        sizes={Sizes.Summary}
                    />
                </EventBookingLink>
        }
        header={
            <>
                <h1>
                    <EventBookingLink
                        siteContext={siteContext}
                        {...event}
                    >
                        { event.title }
                    </EventBookingLink>
                </h1>
                <p className={styles.HeaderParagraph}>
                    <span className={styles.Dates}>
                        {
                            freeDate !== null ? freeDate :
                            startDate === null || endDate === null ? <></> :
                                `${formatDateRange(startDate,endDate)} ${(new Date(startDate).getFullYear())}`
                        }
                    </span>
                    {
                        venue ? <><span className={styles.Spacer}>&nbsp;|&nbsp;</span><span className={styles.VenueSummary}>{shortVenueSummary(venue.city,venue.region,venue.country)}</span></> : null
                    }
                </p>
            </>
        }
        summary={
            <>
                <div className={styles.TagLine}>
                    <Markdown
                        siteContext={siteContext}
                        pageContext={pageContext}
                        articleContext={articleContext}
                    >
                        {tagline}
                    </Markdown>
                </div>
                {
                    startDate !== null && (new Date(startDate)).getTime() < (new Date()).getTime() ? <></> :
                    links.length > 0 ?
                        <ul className={styles.Links}>
                            {links.map(link =>
                                <li key={link.id}>
                                    <ComponentMenuExternalLink
                                        key={link.id}
                                        siteContext={siteContext}
                                        pageContext={pageContext}
                                        articleContext={articleContext}
                                        {...link}
                                    />
                                </li>
                            )}
                        </ul>
                    :
                        <EventBookingLink
                            siteContext={siteContext}
                            {...event}
                            className={`${buttonStyles.Link} ${styles.Book}`}
                        >
                            { bookingLinkText ?? 'Book now' }
                        </EventBookingLink>
                }
                {
                    details !== null &&
                        <details>
                            <summary>More details…</summary>
                            <section>
                                <Markdown
                                    siteContext={siteContext}
                                    pageContext={pageContext}
                                    articleContext={articleContext}
                                >
                                    {summary}
                                </Markdown>
                                {/*
                                    <Markdown
                                        pageContext={pageContext}
                                        articleContext={articleContext}
                                    >
                                        {details}
                                    </Markdown>
                                */}
                            </section>
                        </details>
                }
            </>
        }
    />
