import { ContentTypeView, Sizes } from 'utility/utility'
import { Markdown } from 'components/shared/Markdown/Markdown'
import { MagazineSummaryFragment } from 'generated/graphql'
import { MagazineLink } from 'content-types/Magazine/Magazine.Link/Magazine.Link'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { MetadataTag } from 'components/shared/MetadataTag/MetadataTag'
import { Summary } from 'components/shared/Summary/Summary'

export const MagazineSummary: ContentTypeView<MagazineSummaryFragment> = ({
    summary,
    picture,
    siteContext,
    pageContext,
    articleContext,
    ...magazine
}) =>
    <Summary
        image={
            <MagazineLink
                siteContext={siteContext}
                {...magazine}
            >
                <ComponentImagePicture
                    {...picture}
                    sizes={Sizes.Summary}
                />
            </MagazineLink>
        }
        header={
            <>
                <MetadataTag>Magazine</MetadataTag>
                <h1>
                    <MagazineLink
                        siteContext={siteContext}
                        {...magazine}
                    >
                        { magazine.title }
                    </MagazineLink>
                </h1>
            </>
        }
        summary={
            <Markdown
                siteContext={siteContext}
                pageContext={pageContext}
                articleContext={articleContext}
            >
                {summary}
            </Markdown>
        }
    />
