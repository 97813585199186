import { ContentTypeView, Sizes } from 'utility/utility'
import { Markdown } from 'components/shared/Markdown/Markdown'
import { MarketReportSummaryFragment } from 'generated/graphql'
import { MarketReportLink } from 'content-types/MarketReport/MarketReport.Link/MarketReport.Link'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { MetadataTag } from 'components/shared/MetadataTag/MetadataTag'
import { Summary } from 'components/shared/Summary/Summary'

export const MarketReportSummary: ContentTypeView<MarketReportSummaryFragment> = ({
    heroArea,
    summary,
    siteContext,
    pageContext,
    articleContext,
    ...marketReport
}) =>
    <Summary
        image={
            heroArea?.image === undefined || heroArea.image === null ? <></> :
                <MarketReportLink
                    siteContext={siteContext}
                    {...marketReport}
                >
                    <ComponentImagePicture
                        {...heroArea.image}
                        sizes={Sizes.Summary}
                    />
                </MarketReportLink>
        }
        header={
            <>
                <MetadataTag>
                    Market Report
                </MetadataTag>
                <h1>
                    <MarketReportLink
                        siteContext={siteContext}
                        {...marketReport}
                    >
                        { marketReport.title }
                    </MarketReportLink>
                </h1>
            </>
        }
        summary={
            <Markdown
                siteContext={siteContext}
                pageContext={pageContext}
                articleContext={articleContext}
            >
                {summary ?? ''}
            </Markdown>
        }
    />
