import { EventJsonLd } from 'next-seo'

import { EventSummaryFragment } from 'generated/graphql'
import { strapiPublicImage, type Metadata } from 'utility/utility'

export const EventMetadata: Metadata<EventSummaryFragment> = ({
    title,
    summary,
    startDate,
    endDate,
    picture,
    venue,
    seo
}) =>
    <EventJsonLd
        useAppDir={true}
        type='BusinessEvent'
        name={seo?.metaTitle ?? title}
        description={seo?.metaDescription ?? summary}
        startDate={startDate ?? ""}
        endDate={endDate ?? ""}
        location={{
            name: venue?.name ?? "",
            address: {
                streetAddress: '',
                addressLocality: venue?.city ?? '',
                addressRegion: venue?.region ?? '',
                addressCountry: venue?.country ?? ''
            }
        }}
        organizer="Applied Market Information Ltd"
        images={[`${strapiPublicImage(seo?.metaImage?.data?.attributes?.url ?? picture.image.data?.attributes?.url ?? '')}`]}
    />
