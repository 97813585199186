import { ContentTypeView } from 'utility/utility'

import { MarketReportSummary } from 'content-types/MarketReport/MarketReport.Summary/MarketReport.Summary'
import { InsightReportSummary } from 'content-types/InsightReport/InsightReport.Summary/InsightReport.Summary'
import { DatabaseSummary } from 'content-types/Database/Database.Summary/Database.Summary'
import { DatasetSummary } from 'content-types/Dataset/Dataset.Summary/Dataset.Summary'
import { MagazineSummary } from 'content-types/Magazine/Magazine.Summary/Magazine.Summary'
import { NewsletterSummary } from 'content-types/Newsletter/Newsletter.Summary/Newsletter.Summary'

import type { Publication } from './ComponentBodyPublicationBrowser'

export const PublicationSummary: ContentTypeView<Publication> = (publication) => {

    switch ( publication.__typename ) {

        case 'Magazine':
            return <MagazineSummary key={publication.slug} {...publication} />

        case 'Database':
            return <DatabaseSummary key={publication.slug} {...publication} />

        case 'Dataset':
            return <DatasetSummary key={publication.slug} {...publication} />

        case 'InsightReport':
            return <InsightReportSummary key={publication.slug} {...publication} />

        case 'MarketReport':
            return <MarketReportSummary key={publication.slug} {...publication} />

        case 'Newsletter':
            return <NewsletterSummary key={publication.title} {...publication} />

        case undefined:
            return <></>

    }

}
