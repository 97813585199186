'use client'

import { produce } from 'immer'
import { useRouter, usePathname, useSearchParams } from 'next/navigation'

import type { Publication } from './ComponentBodyPublicationBrowser'
import { BodyObjectView } from 'utility/utility'
import { PublicationSummary } from './PublicationSummary'
import { Filters } from 'components/shared/Filters/Filters'
import { PublicationTypeFilter } from 'components/filters/PublicationTypeFilter/PublicationTypeFilter'
import { PortfolioFilter } from 'components/filters/PortfolioFilter/PortfolioFilter'
import { TopicFilter } from 'components/filters/TopicFilter/TopicFilter'

import styles from './ComponentBodyPublicationBrowser.module.scss'


const types: { [key: string]: string } = {
    'database': 'Database',
    'dataset': 'Dataset',
    'insight-report': 'InsightReport',
    'magazine': 'Magazine',
    'market-report': 'MarketReport',
    'newsletter': 'Newsletter'
} as const

export const PublicationFilterList: BodyObjectView<{
    publications: readonly Publication[],
}> =
    ({
        publications,
        siteContext,
        pageContext,
        articleContext
    }) => {

        const router = useRouter()
        const pathname = usePathname()
        const searchParams = useSearchParams()

        const type      = searchParams.get('type')
        const portfolio = searchParams.get('portfolio')
        const topic     = searchParams.get('topic')

        const updateQuery = (updates: { [key: string]: string } ): void => {
            const params = produce(Object.fromEntries(searchParams.entries()), draft => {
                Object.entries(updates).forEach( ([field,value]) => {
                    if ( value === 'all' ) {
                        delete draft[field]
                    }
                    else {
                        draft[field] = value
                    }
                })
            })
            const query = new URLSearchParams([...Object.entries(params)])
            router.replace(
                `${pathname}${ query.size === 0 ? '' : `?${query.toString()}` }`,
                { scroll: false }
            )
        }

        const setType = (type: string): void =>
            updateQuery({type})

        const setPortfolio = (portfolio: string): void => {
            updateQuery({
                portfolio,
                topic: 'all'
            })
        }

        const setTopic = (topic: string): void =>
            updateQuery({topic})

        return (
            <>
                <Filters className={styles.Filters}>
                    <PublicationTypeFilter
                        key='type'
                        value={type}
                        onChange={setType}
                        className={styles.TypeFilter}
                    />
                    <PortfolioFilter
                        key='portfolio'
                        value={portfolio}
                        onChange={setPortfolio}
                        className={styles.PortfolioFilter}
                        objects={publications}
                        expand={false}
                    />
                    <TopicFilter
                        key='topic'
                        value={topic}
                        onChange={setTopic}
                        portfolio={portfolio ?? ''}
                        className={styles.TopicFilter}
                        pageContext={pageContext}
                        objects={publications}
                        expand={false}
                    />
                </Filters>
                <ul key='articles'>
                    {
                        publications.map( publication => {

                            const visible =
                                (
                                       type === null
                                    || type === 'all'
                                    || publication.__typename === types[type]
                                )
                                &&
                                (
                                       portfolio === null
                                    || portfolio === 'all'
                                    || publication.portfolios?.data.find( p => p.attributes?.slug === portfolio ) !== undefined
                                )
                                &&
                                (
                                       topic === null
                                    || topic === 'all'
                                    || publication.topics?.data.find( t => t.attributes?.slug === topic ) !== undefined
                                )

                            const publicationSummary = <PublicationSummary
                                siteContext={siteContext}
                                pageContext={pageContext}
                                articleContext={articleContext}
                                {...publication}
                            />

                            return (
                                <li key={publicationSummary.key} data-visible={visible}>
                                    {publicationSummary}
                                </li>
                            )

                        })
                    }
                </ul>
            </>
        )

    }

