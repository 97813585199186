import { ContentTypeView, Sizes } from 'utility/utility'
import { Markdown } from 'components/shared/Markdown/Markdown'
import { InsightReportSummaryFragment } from 'generated/graphql'
import { InsightReportLink } from 'content-types/InsightReport/InsightReport.Link/InsightReport.Link'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { MetadataTag } from 'components/shared/MetadataTag/MetadataTag'
import { Summary } from 'components/shared/Summary/Summary'

import styles from './InsightReport.Summary.module.scss'

export const InsightReportSummary: ContentTypeView<InsightReportSummaryFragment> = ({
    summary,
    picture,
    siteContext,
    pageContext,
    articleContext,
    ...insightReport
}) =>
    <Summary
        image={
            <InsightReportLink
                siteContext={siteContext}
                {...insightReport}
            >
                <ComponentImagePicture
                    {...picture}
                    sizes={Sizes.Summary}
                />
            </InsightReportLink>
        }
        header={
            <>
                <MetadataTag>Insight Report</MetadataTag>
                <h1>
                    <InsightReportLink
                        siteContext={siteContext}
                        {...insightReport}
                    >
                        { insightReport.title }
                    </InsightReportLink>
                </h1>
            </>
        }
        summary={
            <Markdown
                siteContext={siteContext}
                pageContext={pageContext}
                articleContext={articleContext}
            >
                {summary}
            </Markdown>
        }
        className={styles.Summary}
    />
