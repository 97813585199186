import { FC } from 'react'
import { Portfolio, WithPortfolioFragment } from 'generated/graphql'

type ChangeHandler = (value: string) => void

type WithPortfolios = {
    portfolios: WithPortfolioFragment | null
}

type PortfolioFilterArgs = {
    value: string | null,
    onChange: ChangeHandler,
    className?: string,
    objects: readonly WithPortfolios[],
    expand: boolean
}

export const PortfolioFilter: FC<PortfolioFilterArgs> =
    ({
        value,
        onChange,
        className,
        objects
    }) => {

        const portfolios =
            objects
                .flatMap( object =>
                    object.portfolios?.data.map( portfolio =>
                        portfolio.attributes
                    )
                )
                .filter( (portfolio,index,array): portfolio is Pick<Portfolio,'slug'|'title'> =>
                    portfolio !== undefined
                    && portfolio !== null
                    && index === array.findIndex( x => x?.slug === portfolio.slug )
                )
                .sort( (a, b) => a.title.localeCompare(b.title) )

        return <span className={className}>
            <label>Industry</label>
            <select
                value={value ?? ''}
                onChange={
                    ({target:{value}}): void =>
                        onChange(value)
                }
                title='Industry'
            >
                <option value='all'>All</option>
                {
                    portfolios.map( ({slug,title}) =>
                        <option key={slug} value={slug}>
                            {title}
                        </option>
                    )
                }
            </select>
        </span>

    }

