'use client'

import { produce } from 'immer'
import { useRouter, usePathname, useSearchParams } from 'next/navigation'

import { ArticleFeaturedFragment } from 'generated/graphql'

import { BodyObjectView } from 'utility/utility'
import { Filters } from 'components/shared/Filters/Filters'
import { ActivityFilter } from 'components/filters/ActivityFilter/ActivityFilter'
import { ArticleTypeFilter } from 'components/filters/ArticleTypeFilter/ArticleTypeFilter'
import { PortfolioFilter } from 'components/filters/PortfolioFilter/PortfolioFilter'
import { TopicFilter } from 'components/filters/TopicFilter/TopicFilter'

import { ArticleFeatured } from 'content-types/Article/Article.Featured/Article.Featured'

import styles from './ComponentBodyArticleBrowser.module.scss'

export const ArticleFilterList: BodyObjectView<{
    articles: readonly ArticleFeaturedFragment[],
    expandFilters: boolean | null,
    typeFilter: boolean | null,
    activityFilter: boolean | null,
    portfolioFilter: boolean | null,
    topicFilter: boolean | null
}> =
    ({
        articles,
        siteContext,
        pageContext,
        articleContext,
        expandFilters,
        typeFilter,
        activityFilter,
        portfolioFilter,
        topicFilter
    }) => {

        const router = useRouter()
        const pathname = usePathname()
        const searchParams = useSearchParams()

        const type      = searchParams.get('type')
        const activity  = searchParams.get('activity')
        const portfolio = searchParams.get('portfolio')
        const topic     = searchParams.get('topic')

        const updateQuery = (updates: { [key: string]: string } ): void => {
            const params = produce(Object.fromEntries(searchParams.entries()), draft => {
                Object.entries(updates).forEach( ([field,value]) => {
                    if ( value === 'all' ) {
                        delete draft[field]
                    }
                    else {
                        draft[field] = value
                    }
                })
            })
            const query = new URLSearchParams([...Object.entries(params)])
            router.replace(
                `${pathname}${ query.size === 0 ? '' : `?${query.toString()}` }`,
                { scroll: false }
            )
        }

        const setType = (type: string): void =>
            updateQuery({type})

        const setActivity = (activity: string): void =>
            updateQuery({activity})

        const setPortfolio = (portfolio: string): void => {
            updateQuery({
                portfolio,
                topic: 'all'
            })
        }

        const setTopic = (topic: string): void =>
            updateQuery({topic})

        return (
            <>
                <Filters className={styles.Filters} expanded={expandFilters ?? false}>
                    {
                        !(typeFilter ?? true) ? <></> :
                            <ArticleTypeFilter
                                key='article_type'
                                value={type}
                                onChange={setType}
                                className={styles.TypeFilter}
                                objects={articles}
                                expand={expandFilters ?? false}
                            />
                    }
                    {
                        !(activityFilter ?? true) ? <></> :
                            <ActivityFilter
                                key='activity'
                                value={activity}
                                onChange={setActivity}
                                className={styles.ActivityFilter}
                                objects={articles}
                                expand={expandFilters ?? false}
                            />
                    }
                    {
                        !(portfolioFilter ?? true) ? <></> :
                            <PortfolioFilter
                                key='portfolio'
                                value={portfolio}
                                onChange={setPortfolio}
                                className={styles.PortfolioFilter}
                                objects={articles}
                                expand={expandFilters ?? false}
                            />
                    }
                    {
                        !(topicFilter ?? true) ? <></> :
                            <TopicFilter
                                key='topic'
                                value={topic}
                                onChange={setTopic}
                                portfolio={portfolio ?? ''}
                                className={styles.TopicFilter}
                                pageContext={pageContext}
                                objects={articles}
                                expand={expandFilters ?? false}
                            />
                    }
                </Filters>
                <ul key='articles' className={styles.Articles}>
                    {
                        articles.map( article =>
                            <li
                                key={article.slug}
                                data-visible={
                                    (
                                           type === null
                                        || type === 'all'
                                        || article.articleType?.data?.attributes?.title === type
                                    )
                                    &&
                                    (
                                           activity === null
                                        || activity === 'all'
                                        || article.activities?.data.find( a => a.attributes?.slug === activity ) !== undefined
                                    )
                                    &&
                                    (
                                           portfolio === null
                                        || portfolio === 'all'
                                        || article.portfolios?.data.find( p => p.attributes?.slug === portfolio ) !== undefined
                                    )
                                    &&
                                    (
                                           topic === null
                                        || topic === 'all'
                                        || article.topics?.data.find( t => t.attributes?.slug === topic ) !== undefined
                                    )
                                }
                            >
                                <ArticleFeatured
                                    {...article}
                                    siteContext={siteContext}
                                    pageContext={pageContext}
                                    articleContext={articleContext}
                                />
                            </li>
                        )
                    }
                </ul>
            </>
        )

    }

