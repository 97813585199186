import { FC } from 'react'
import { Topic, WithTopicFragment } from 'generated/graphql'
import { type PageContext } from 'context/PageContext'

type ChangeHandler = (value: string) => void

type WithTopics = {
    topics: WithTopicFragment | null
}

type TopicFilterArgs = {
    value: string | null,
    onChange: ChangeHandler,
    className?: string,
    objects: readonly WithTopics[],
    portfolio: string,
    pageContext: PageContext,
    expand: boolean
}

export const TopicFilter: FC<TopicFilterArgs> =
    ({
        value,
        onChange,
        objects,
        portfolio = '0',
        className
    }) => {

        const topics =
            objects
                .flatMap( object =>
                    object.topics?.data.map( topic =>
                        topic.attributes
                    )
                )
                .filter( (topic,index,array): topic is Pick<Topic,'slug'|'title'|'portfolio'> =>
                    topic !== undefined
                    && topic !== null
                    && index === array.findIndex( x => x?.slug === topic.slug )
                )
                .filter ( topic =>
                    portfolio === '0'
                    || portfolio === ''
                    || -1 !== topic.portfolio?.data.findIndex( p => p.attributes?.slug === portfolio )
                )
                .sort( (a, b) => a.title.localeCompare(b.title) )

        return (
            <span className={className}>
                <label>Topic</label>
                <select
                    value={value ?? ''}
                    onChange={
                        ({target:{value}}): void =>
                            onChange(value)
                    }
                    title='Topic'
                >
                    <option value='all'>All</option>
                    {
                        topics.map( ({slug,title}) =>
                            <option key={slug} value={slug}>
                                {title}
                            </option>
                        )
                    }
                </select>
            </span>

        )

    }

