import { FC } from 'react'
import { SpeakerFeaturedFragment } from 'generated/graphql'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { Sizes } from 'utility/Sizes'
import { SpeakerModal } from '../Speaker.Modal/Speaker.Modal'
import { useState } from 'react'

import styles from './Speaker.Featured.module.scss'

export const SpeakerFeatured: FC<SpeakerFeaturedFragment> = ({
    ...speaker
}) => {

    const [ open, setOpen ] = useState<boolean>(false)
    
    return (
        <>
            <SpeakerModal open={open} setOpen={setOpen} {...speaker} />
            <div className={styles.Speaker} onClick={ () => setOpen(true) }>
                {speaker.photo ?
                    <ComponentImagePicture
                        {...speaker.photo}
                        className={styles.Image}
                        sizes={Sizes.Featured}
                    />
                    : <></>
                }
                <div className={styles.SpeakerText}>
                    <h2>{speaker.forename} {speaker.surname}</h2>
                    <h3>{speaker.jobTitle}</h3>
                    <p>{speaker.company?.data?.attributes?.title}</p>
                </div>
            </div>
        </>
    )
}
