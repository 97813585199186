'use client'

import { produce } from 'immer'
import { useRouter, usePathname, useSearchParams } from 'next/navigation'
import { FC } from 'react'
import styles from './ComponentBodySessionBrowser.module.scss'

export const TheatreFilters: FC<{theatres: (string|null|undefined)[], active: string|null}> =
    ({
        active,
        theatres
    }) => {

        const router = useRouter()
        const pathname = usePathname()
        const searchParams = useSearchParams()

        const updateQuery = (
            value: string
        ): void => {
            const field = "theatre"
            const params = produce(Object.fromEntries(searchParams.entries()), draft => {
                if ( value === 'all' || value === searchParams.get(field) ) {
                    delete draft[field]
                }
                else {
                    draft[field] = value
                }
            })
            const query = new URLSearchParams([...Object.entries(params)])
            router.replace(
                `${pathname}${ query.size === 0 ? '' : `?${query.toString()}` }`,
                { scroll: false }
            )
        }

        const theatreNames = theatres.filter(theatre => theatre !== null && theatre !== undefined)
        return (
            <span data-number={theatreNames.length + 1} className={styles.Theatres}>
                <button key="all" data-active={active===null} onClick={() => updateQuery("all")}>
                    All Theatres
                </button>
                {
                    theatres
                        .map( theatre =>
                            theatre === null || theatre === undefined ? <></> :
                                <button key={theatre} data-active={active===theatre} onClick={() => updateQuery(theatre)}>
                                    {theatre}
                                </button>
                        )
                }
            </span>
        )
    }